export const en = {
  'contact.header': 'Contact info',

  'contacts.header': 'My contacts',
  'contacts.create': 'New contact',
  'contacts.edit': 'Edit contact',

  'welcome.header': 'Welcome page',
  'welcome.login-form.email': 'Email',
  'welcome.login-form.password': 'Password',

  'events.header': 'My events',
  'events.create': 'New event',
  'events.edit': 'Edit event',

  'home.header': 'Home',
  'profile.header': 'My Profile',
  'profile.oldPassword': 'Old Password',
  'profile.newPassword': 'New Password',
  'profile.repeatNewPassword': 'Repeat New Password',
  'profile.logout': 'Log Out',
  'profile.email': 'Email',
  'profile.roles': 'Roles',
  'profile.change-password': 'Change password',
  'settings.header': 'My settings',
  'forgot-login.header': 'Forgot Password',
  'forgot-login.button.reset': 'e-mail reset instructions',

  'registration.header': 'Free Member Registration',
  'registration.form.country': 'Country',
  'registration.form.language': 'Language',
  'registration.button.continue': 'Continue to Setup your Account',
  'registration.button.sign-in': 'Sign In',
  'registration.text.acknowledge':
    'By creating an account, you acknowledge that you have read our Privacy Policy and agree to our Terms of Use.',
  'registration.text.registered': 'Already have an Account?',

  'validation.messages.required': 'Required',
  'validation.messages.length': 'Length should be {0} - {1} symbols',
  'validation.messages.email': 'Entered value is not a valid email',

  'button.update': 'Update',
  'button.remove': 'Remove',
  'button.submit': 'Submit',
  'button.login': 'Login',
  'button.back': 'Back',
  'button.cancel': 'Cancel',
  'button.settings': 'Settings',
  'button.forgot-login': 'Forgot Password',
  'button.registration': 'Free Member Registration',

  'menu.users': 'Users',
  'menu.roles': 'Roles',
  'menu.permissions': 'Permissions',
  'menu.permissions-objects': 'Permissions',
  'menu.proposal': 'Proposal',

  // Common pages
  'common.access-denied.title': 'Access Denied',
  'common.access-denied.p1': "You don't have permissions to access this page.",
  'common.access-denied.p2': 'For more details, please contact customer support.',
  'common.back-home': 'Back Home',
  'common.warning': 'Warning',
  'common.search': 'Search...',
  'common.note': 'Note',

  // Addresses
  'addresses.add-new': 'Add Address',
  'addresses.edit-bulk': 'Edit Addresses',
  'addresses.delete-bulk': 'Delete Addresses',
  'addresses.delete-warning-title': 'Warning',
  'addresses.delete-warning-desc': 'Are you sure you want to delete these Addresses?',
  'addresses.create-new': 'Create Address',
  'addresses.addresses-number': 'Address #{0}',

  'addresses.name': 'Name',
  'addresses.type': 'Type',
  'addresses.country': 'Country',
  'addresses.city': 'City',
  'addresses.street': 'Street',
  'addresses.street2': 'Street 2',

  // Colors
  'colors.add-new': 'Add Color',
  'colors.edit-bulk': 'Edit Colors',
  'colors.delete-bulk': 'Delete Colors',
  'colors.delete-warning-title': 'Warning',
  'colors.delete-warning-desc': 'Are you sure you want to delete these Colors?',
  'colors.create-new': 'Create Color',
  'colors.colors-number': 'Color #{0}',
  'colors.name': 'Name',
  'colors.color': 'Color',

  // Agencies
  'agencies.add-new': 'Add Agency',
  'agencies.edit-bulk': 'Edit Agencies',
  'agencies.delete-bulk': 'Delete Agencies',
  'agencies.delete-warning-title': 'Warning',
  'agencies.delete-warning-desc': 'Are you sure you want to delete these Agencies?',
  'agencies.create-new': 'Create Agency',
  'agencies.agencies-number': 'Agency #{0}',

  'agencies.name': 'Name',
  'agencies.info': 'Info',
  'agencies.location': 'Location',
  'agencies.non-billable': 'Non Billable',

  // Agencies Markup
  'agenciesMarkup.add-new': 'Add Agency Markup',
  // 'agenciesMarkup.edit-bulk': 'Edit Agencies',
  // 'agenciesMarkup.delete-bulk': 'Delete Agencies',
  'agenciesMarkup.delete-warning-title': 'Warning',
  'agenciesMarkup.delete-warning-desc': 'Are you sure you want to delete these Agencies?',
  'agenciesMarkup.create-new': 'Create Agency Markup',
  'agenciesMarkup.agenciesMarkup-number': 'Agency Markup #{0}',
  'agenciesMarkup.agencyId': 'Agency',
  'agenciesMarkup.markup': 'Regular rate markup (%)',
  'agenciesMarkup.overtimeMarkup': 'Overtime rate markup (%)',
  'agenciesMarkup.overtimeRate': 'Overtime rate multiplier',
  'agenciesMarkup.effectiveDate': 'Effective date',

  // Positions
  'positions.add-new': 'Add Job Title',
  'positions.edit-bulk': 'Edit Job Titles',
  'positions.delete-bulk': 'Delete Job Titles',
  'positions.delete-warning-title': 'Warning',
  'positions.delete-warning-desc': 'Are you sure you want to delete these Job Titles?',
  'positions.create-new': 'Create Job Title',
  'positions.positions-number': 'Job Title #{0}',
  'positions.name': 'Name',

  // Languages
  'languages.add-new': 'Add Language',
  'languages.edit-bulk': 'Edit Languages',
  'languages.delete-bulk': 'Delete Languages',
  'languages.delete-warning-title': 'Warning',
  'languages.delete-warning-desc': 'Are you sure you want to delete these Languages?',
  'languages.create-new': 'Create Language',
  'languages.languages-number': 'Language #{0}',
  'languages.name': 'Name',

  // Areas
  'areas.add-new': 'Add Cost Center',
  'areas.edit-bulk': 'Edit Cost Centers',
  'areas.delete-bulk': 'Delete Cost Centers',
  'areas.delete-warning-title': 'Warning',
  'areas.delete-warning-desc': 'Are you sure you want to delete these Cost Centers?',
  'areas.create-new': 'Create Cost Center',
  'areas.areas-number': 'Cost Center #{0}',
  'areas.name': 'Name',

  // buildingsPairs
  'buildingPairs.building1Id': 'Location 1',
  'buildingPairs.building2Id': 'Location 2',
  'buildingPairs.add-new': 'Add Billable Transition',
  'buildingPairs.buildingPairs-number': 'Billable Transition #{0}',
  'buildingPairs.create-new': 'Create Billable Transition',

  // buildingsAreas
  'buildingsAreas.add-new': 'Add Buildings Area',
  'buildingsAreas.edit-bulk': 'Edit Buildings Areas',
  'buildingsAreas.delete-bulk': 'Delete Buildings Areas',
  'buildingsAreas.delete-warning-title': 'Warning',
  'buildingsAreas.delete-warning-desc': 'Are you sure you want to delete these Buildings Areas?',
  'buildingsAreas.create-new': 'Create Buildings Area',
  'buildingsAreas.areas-number': 'Buildings Area #{0}',
  'buildingsAreas.areaName': 'Area Name',
  'buildingsAreas.areaType': 'Area Type',
  'buildingsAreas.areaType.working': 'Billable',
  'buildingsAreas.areaType.idle': 'Non Billable',
  'buildingsAreas.buildingAreaType': 'Building Area Type',
  'buildingsAreas.buildingAreaType.active': 'Active',
  'buildingsAreas.buildingAreaType.archived': 'Archived',
  'buildingsAreas.buildingName': 'Building Name',
  'buildingsAreas.buildingsAreas-number': 'Buildings Area #{0}',

  // Building config
  'buildingConfigs.buildingId': 'Location',
  'buildingConfigs.minBillableTime': 'Minimum billable time',
  'buildingConfigs.breakPaidTime': 'Break paid time',
  'buildingConfigs.effectiveDate': 'Effective date',
  'buildingConfigs.add-new': 'Add Location Configurable Time',
  'buildingConfigs.create-new': 'Create Location Configurable Time',
  'buildingConfigs.billingOnPremiseLaunchUnpaidTime': 'Lunch unpaid time',
  'buildingConfigs.billingOnPremiseMinBillableTime': 'Minimum time on premise',
  'buildingConfigs.productionFloorBathPaidTime': 'Bathroom break paid time',
  'buildingConfigs.productionFloorBreakPaidTime': 'Break paid time',
  'buildingConfigs.productionFloorLaunchPaidTime': 'Lunch paid time',
  'buildingConfigs.productionFloorMinBillableTime': 'Minimum billable time',
  'buildingConfigs.buildingConfigs-number': 'Location Configurable Time #{0}',
  'buildingConfigs.productionFloor': 'Production Floor',
  'buildingConfigs.billingOnPremise': 'On Premise',


  // Breaktimes
  'breaktimes.add-new': 'Add Breaktime',
  'breaktimes.edit-bulk': 'Edit Breaktimes',
  'breaktimes.delete-bulk': 'Delete Breaktimes',
  'breaktimes.delete-warning-title': 'Warning',
  'breaktimes.delete-warning-desc': 'Are you sure you want to delete these Breaktimes?',
  'breaktimes.create-new': 'Create Breaktime',
  'breaktimes.breaktimes-number': 'Breaktime #{0}',
  'breaktimes.name': 'Name',
  'breaktimes.start': 'Start',
  'breaktimes.end': 'End',

  // Buildings
  'buildings.add-new': 'Add Location',
  'buildings.edit-bulk': 'Edit Locations',
  'buildings.delete-bulk': 'Delete Locations',
  'buildings.delete-warning-title': 'Warning',
  'buildings.delete-warning-desc': 'Are you sure you want to delete these Locations?',
  'buildings.create-new': 'Create Location',
  'buildings.buildings-number': 'Location #{0}',
  'buildings.name': 'Name',
  'buildings.enableShiftPlanning': 'Enable Shift Planning',
  'buildings.timezone': 'Timezone',
  'buildings.timezone-helper-text':
    'Please select valid time zone of the location, since it is used in multiple features of the staffing application',

  // Application settings
  'application-settings.UPDATE_WEEK_TIMESHEET_TIMEOUT': 'Update week timesheet timeout, ms',
  'application-settings.TIMESHEET_APPROVING_NOTIFICATION_THRESHOLD_IN_DAYS':
    'Timesheet approving notification threshold, days',
  'application-settings.WF_SYNC_TIMEOUT': 'FW sync timeout, ms',
  'application-settings.TIMESHEET_AUTO_APPROVAL_INTERVAL_DAYS': 'Timesheet auto approval interval, days',
  'application-settings.FW_REQUEST_BATCH_SIZE': 'FW request batch size, pcs',
  'application-settings.TIMESHEET_APPROVAL_THRESHOLD_IN_DAYS': 'Timesheet approval threshold, days',
  'application-settings.ALLOW_CHECK_IN_BEFORE_EVENT_START_MINUTES': 'Allow checkin before event start, minutes',
  'application-settings.host': 'Host',
  'application-settings.port': 'Port',
  'application-settings.username': 'Username',
  'application-settings.password': 'Password',
  'application-settings.dir': 'Dir',
  'application-settings.file-type-error': 'You can only upload pdf file!',
  'application-settings.file-size-error': 'File must be smaller than 10MB!',
  'application-settings.submit-button': 'Submit',
  'application-settings.upload-button': 'Upload',
  'application-settings.upload-label': 'User Agreement',
  'application-settings.successful-submit': 'Settings updated successfully',
  'application-settings.ARCHIVE_DATA_INTERVAL_DAYS': 'Archive data interval days',

  // Employees
  'employees.delete-warning-desc': 'Are you sure you want to delete these Employees?',
  'employees.add-new': 'Add Employee',
  'employees.export-to-excel': 'Export to excel',
  'employees.create-new': 'Create Employee',
  'employees.employee-number': 'Employee #{0}',
  'employees.bulk-update': 'Bulk Update',
  'employees.agencyInternalId': 'Agency Internal Id',
  'employees.status': 'Status',
  'employees.status.new': 'New',
  'employees.status.temporary': 'Temporary',
  'employees.status.permanent': 'Permanent',
  'employees.status.terminated': 'Terminated',
  'employees.status.terminated_agency': 'Terminated by Agency',
  'employees.firstName': 'First Name',
  'employees.middleName': 'Middle Name',
  'employees.lastName': 'Last Name',
  'employees.dobKey': 'dob Key',
  'employees.photoUrl': 'Photo Url',
  'employees.photo': 'Photo',
  'employees.agencyId': 'Agency',
  'employees.areaId': 'Area',
  'employees.position': 'Position',
  'employees.supervisorId': 'Supervisor',
  'employees.shift': 'Shift',
  'employees.colorId': 'Color',
  'employees.line': 'Line',
  'employees.startDate': 'Start Date',
  'employees.orientationDate': 'Orientation Date',
  'employees.building': 'Building',
  'employees.trainedSlitter': 'Trained Slitter',
  'employees.trainedOverwrap': 'Trained Overwrap',
  'employees.locker': 'Locker',
  'employees.email': 'Email',
  'employees.birthday': 'Date of Birth',
  'employees.phone': 'Phone',
  'employees.address1': 'Address1',
  'employees.address2': 'Address2',
  'employees.city': 'City',
  'employees.zipcode': 'Zipcode',
  'employees.invitationLanguageId': 'Invitation Language',
  'employees.breakTimes': 'Break Times',
  'employees.lunchTime': 'Lunch Time',
  'employees.parkingTag': 'Parking Tag',
  'employees.term': 'Term',
  'employees.termDescription': 'Term Description',
  'employees.vestNumber': 'Vest Number',
  'employees.comment': 'Comment',
  'employees.kioskPinCode': 'Kiosk Pin Code',

  // Shifts
  'shifts.add-new': 'Add Shift',
  'shifts.edit-bulk': 'Edit Shifts',
  'shifts.delete-bulk': 'Delete Shifts',
  'shifts.delete-warning-title': 'Warning',
  'shifts.delete-warning-desc': 'Are you sure you want to delete these Shifts?',
  'shifts.create-new': 'Create Shift',
  'shifts.shifts-number': 'Shift #{0}',
  'shifts.name': 'Name',
  'shifts.start': 'Start',
  'shifts.end': 'End',
  'shifts.type': 'Type',
  'shifts.type.day': 'Day',
  'shifts.type.night': 'Night',
  'shifts.building': 'Location',
  'shifts.enableShiftPlanning': 'Enable Shift Planning',

  // Supervisors
  'supervisors.add-new': 'Add Supervisor',
  'supervisors.edit-bulk': 'Edit Supervisors',
  'supervisors.delete-bulk': 'Delete Supervisors',
  'supervisors.delete-warning-title': 'Warning',
  'supervisors.delete-warning-desc': 'Are you sure you want to delete these Supervisors?',
  'supervisors.create-new': 'Create Supervisor',
  'supervisors.supervisors-number': 'Supervisor #{0}',
  'supervisors.name': 'Name',
  'supervisors.start': 'Start',
  'supervisors.end': 'End',
  'supervisors.type': 'Type',

  // Working Log
  'workingLogs.delete-warning-desc': 'Are you sure you want to delete these WorkLogs?',
  'workingLogs.add-new': 'Add Working Log',
  'workingLogs.create-new': 'Create Working Log',
  'workingLogs.edit-title': 'Working Log #{0}',
  'workingLogs.bulk-update': 'Bulk Update',
  'workingLogs.visitorId': 'friendlyway ID',
  'workingLogs.agencyId': 'Agency',
  'workingLogs.visitorFirstName': 'First Name',
  'workingLogs.visitorLastName': 'Last Name',
  'workingLogs.visitorMiddleName': 'Mid Name',
  'workingLogs.checkInTurnStile': 'Checkin Turnstile',
  'workingLogs.checkInTime': 'Checkin Time',
  'workingLogs.checkInDate': 'Checkin Date',
  'workingLogs.checkInRawDate': 'Checkin Raw Date',
  'workingLogs.checkOutTime': 'Checkout Time',
  'workingLogs.checkOutDate': 'Checkout Date',
  'workingLogs.checkOutTurnStile': 'Checkout Turnstile',
  'workingLogs.checkOutRawDate': 'Checkout Raw Date',
  'workingLogs.buildingId': 'Location',
  'workingLogs.supervisorId': 'Supervisor',
  'workingLogs.shiftPlannedId': 'Shift Planned',
  'workingLogs.shiftAssignedId': 'Shift Assigned',
  'workingLogs.colorAssignedId': 'Color Assigned',
  'workingLogs.colorActualId': 'Actual Color',
  'workingLogs.weekOf': 'Week of',
  'workingLogs.breakTimes': 'BreakTime',
  'workingLogs.status': 'Status',
  'workingLogs.raw': 'Raw',
  'workingLogs.workingHours': 'Time, hours',
  'workingLogs.overtimeHours': 'Overtime, hours',

  // Users
  'users.add-new': 'Add User',
  'users.create-new': 'Create User',
  'users.user-tab': 'User Info',
  'users.agreements-tab': 'Signed Agreements',
  'users.id': 'Id',
  'users.file-name': 'File Name',
  'users.signed-date': 'Sign date',
  'users.user-number': 'User #{0}',
  'users.number': 'Number',
  'users.roles': 'Roles',
  'users.role': 'Role',
  'users.name': 'Name',
  'users.agencyId': 'Agency',
  'users.email': 'Email',
  'users.password': 'Password',
  'users.repeat-password': 'Repeat Password',
  'users.send-invitation': 'Send Invitation',
  'users.status': 'Status',
  'users.is-admin': 'Is Admin',
  'users.expiration-date': 'Expiration Date',
  'users.created-date': 'Created Date',
  'users.onVacation': 'On Vacation',
  'users.blocked': 'Is Blocked',

  // Approver configs
  'approverConfigs.approverUserId': 'Approver',
  'approverConfigs.substitutorUserId': 'Substitutor',
  'approverConfigs.add-new': 'Add approver',
  'approverConfigs.approversConfigs-number': 'Approver #{0}',
  'approverConfigs.create-new': 'Create Approver',

  // Permissions
  'permissions.add-new': 'Add Permission',
  'permissions.create-new': 'Create Permission',
  'permissions.permission-number': 'Permission #{0}',
  'permissions.name': 'Name',
  'permissions.isSystem': 'Is System',
  'permissions.slugname': 'Slug Name',
  'permissions.methods': 'Methods',
  'permissions.action': 'Action',
  'permissions.objectId': 'Object Id',

  // Permissions Objects
  'permissions-objects.add-new': 'Add Permission',
  'permissions-objects.create-new': 'Create Permission',
  'permissions-objects.permission-object-number': 'Permission #{0}',
  'permissions-objects.name': 'Name',
  'permissions-objects.slugname': 'Action',

  // Roles
  'roles.add-new': 'Add Role',
  'roles.create-new': 'Create Role',
  'roles.role-number': 'Role #{0}',
  'roles.number': 'Number',
  'roles.permissions': 'Permissions',
  'roles.permissions-example': 'Permissions Example',
  'roles.password': 'Password',
  'roles.confirm-password': 'Confirm Password',
  'roles.role': 'Role',
  'roles.roles': 'Roles',
  'roles.name': 'Name',
  'roles.numberOfPermissions': 'Number Of Permissions',
  'roles.slugname': 'Slugname',
  'roles.isSystem': 'Is System',
  'roles.user-name': 'User Name',
  'roles.status': 'Status',
  'roles.is-admin': 'Is Admin',
  'roles.expiration-date': 'Expiration Date',
  'roles.created-date': 'Created Date',

  'menu.dictionaries': 'Dictionaries',
  'menu.addresses': 'Addresses',
  'menu.colors': 'Colors',
  'menu.agencies': 'Agencies',
  'menu.agenciesMarkup': 'Agencies Markups',
  'menu.approverConfigs': 'Approver Configs',
  'menu.positions': 'Job Titles',
  'menu.languages': 'Languages',
  'menu.areas': 'Cost Centers',
  'menu.breaktimes': 'Breaktimes',
  'menu.buildingConfig': 'Locations Configurable Times',
  'menu.buildingPairs': 'Billable Transitions',
  'menu.buildings': 'Locations',
  'menu.employees': 'Employees',
  'menu.shifts': 'Shifts',
  'menu.supervisors': 'Supervisors',
  'menu.working_log': 'Working Logs',
  'menu.billings': 'Billings',
  'menu.buildingsAreas': 'Buildings Areas',
  'menu.audit': 'Audit',
  'menu.employeesSyncErrors': 'Sync Errors',
  'menu.musterStations': 'Muster Stations',
  'menu.lines': 'Lines',
  'menu.lockers': 'Lockers',
  'menu.application-settings': 'Application Settings',

  // Table
  'table.actions': 'Actions',
  'table.view': 'View',
  'table.edit': 'Edit',
  'table.delete': 'Delete',
  'table.report': 'Report',
  'table.notes': 'Notes',

  // Audit
  'audit.user': 'User',
  'audit.action': 'Action',
  'audit.name': 'Name',
  'audit.changedFields': 'Changed Fields',
  'audit.audit-number': 'Audit',
  'audit.newData': 'New Data',
  'audit.originalData': 'Original Data',
  'audit.property': 'Property',
  'audit.tableName': 'Table Name',
  'audit.createdAt': 'Date',
  'audit.userName': 'User Name',
  'audit.button.find': 'Find',

  // employeesSyncErrors
  'employeesSyncErrors.params': 'Params (JSON)',
  'employeesSyncErrors.errorResponse': 'Error Response',
  'employeesSyncErrors.isResolved': 'Resolved',
  'employeesSyncErrors.type': 'Type',
  'employeesSyncErrors.ranAt': 'Ran At',

  'employeesSyncErrors.add-new': 'Add',
  'employeesSyncErrors.edit-bulk': 'Edit',
  'employeesSyncErrors.delete-bulk': 'Delete',
  'employeesSyncErrors.employeesSyncErrors-number': 'View',

  'auth.button.sing-in': 'Sign In',
  'auth.message.input-email': 'Please input Email!',
  'auth.message.input-password': 'Please input password!',

  'home.title': 'Authorized user home page',
  'common.loading': 'Loading ...',

  // musterStations
  'musterStations.add-new': 'Add Muster Station',
  'musterStations.edit-bulk': 'Edit Muster Station',
  'musterStations.delete-bulk': 'Delete Muster Station',
  'musterStations.create-new': 'Create Muster Station',
  'musterStations.musterStations-number': 'Muster Station #{0}',
  'musterStations.name': 'Name',
  'musterStations.building': 'Location',
  'musterStations.captain': 'Captain',

  // lines
  'lines.add-new': 'Add Line',
  'lines.edit-bulk': 'Edit Line',
  'lines.delete-bulk': 'Delete Line',
  'lines.create-new': 'Create Line',
  'lines.lines-number': 'Line #{0}',
  'lines.name': 'Name',
  'lines.building': 'Location',

  // agreement page
  'agreement-page.agree-button': 'Agree',

  // lockers
  'lockers.add-new': 'Add Lockers',
  'lockers.lockers-number': 'Locker #{0}',
  'lockers.edit-bulk': 'Edit Locker',
  'lockers.delete-bulk': 'Delete Locker',
  'lockers.create-new': 'Create Lockers',
  'lockers.musterStations-number': 'Locker #{0}',
  'lockers.count': 'Count',
  'lockers.building': 'Location',
  'lockers.number-from': 'From',
  'lockers.number-to': 'To',
};
