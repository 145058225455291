import { Badge } from 'antd';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { colorsStore } from '@/stores';
import { PERMISSION_OBJ_COLOR } from '@/constants/permissions';

export const createState = 'base-layout.colors.create';
export const editState = 'base-layout.colors.edit';
export const bulkEditState = 'base-layout.colors.bulk-edit';
export const listState = 'base-layout.colors';

export const i18nPrefix = 'colors';

export const mainUrl = '/colors';

export const permissionObj = PERMISSION_OBJ_COLOR;
export const store = colorsStore;
export const apiMethods = api.colors;

const createInitValues = {
  color: '#000000',
};

export const fields = [
  {
    name: 'name',
    componentName: 'input',
    params: {
      i18nLabel: 'colors.name',
    },
    rules: [{ required: true }],
  },
  {
    name: 'color',
    componentName: 'input',
    params: {
      type: 'color',
      inputStyle: { width: 80 },
      i18nLabel: 'colors.color',
    },
    rules: [{ required: true }],
  },
];

export const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    sorter: true,
  },
  {
    title: <FormattedMessage id="colors.name" />,
    dataIndex: 'name',
    sorter: true,
  },
  {
    title: <FormattedMessage id="colors.color" />,
    dataIndex: 'color',
    sorter: true,
    render: (color) => <Badge color={color} text={color} />,
  },
];

export default {
  listState,
  createState,
  bulkEditState,
  editState,
  i18nPrefix,
  permissionObj,
  store,
  apiMethods,
  columns,
  fields,
  mainUrl,
  createInitValues,
};
