import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Divider } from 'antd';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import { Button, Form, Upload } from '@/components/antd';
import { UploadOutlined } from '@/components/icons';
import { API_URL, LOCAL_STORAGE_KEY_ACCESS_TOKEN } from '@/constants';
import { useAgreementUpload } from './hooks';
import { formSetup } from '@/pages/application-settings/components/table/setup';
import { getTranslatedString } from '@/utils';
import { Loader } from '@/components/loader';
import { applicationSettingsStore } from '@/stores';

interface Props {
  loading: boolean;
  items: any;
}

export const ApplicationSettingsForm = ({ loading, items }: Props) => {
  const initialValues = {
    ...items,
    ...items?.SFTP_TIMESHEET_UPLOAD_CREDENTIALS,
  };
  const tailLayout = { wrapperCol: { offset: 8, span: 16 } };
  const { initLastAgreement, fileList, handleChange, beforeUpload, onPreview } = useAgreementUpload();
  useEffect(() => {
    initLastAgreement();
  }, []);

  const onFinish = values => {
    const { host, port, username, password, dir, ...filteredValues } = values;
    const sftpConfig = {
      host,
      port,
      username,
      password,
      dir,
    };
    if (host || port || username || password || dir) {
      applicationSettingsStore.postApplicationSettings({
        ...filteredValues,
        SFTP_TIMESHEET_UPLOAD_CREDENTIALS: sftpConfig,
      });
      return;
    }
    applicationSettingsStore.postApplicationSettings(filteredValues);
  };

  return loading ? (
    <Loader size="large" />
  ) : (
    <Form wrapperCol={{ span: 14 }} layout="horizontal" initialValues={initialValues} onFinish={values => onFinish(values)}>
      {formSetup.map(({ sectionLabel, items: sectionItems }) => (
        <div>
          <Divider orientation="left" plain>
            {sectionLabel}
          </Divider>
          <div>
            {sectionItems.map(({ name, rules, formItem, label }) => (
              <Row align="middle">
                <Col flex="0 0 350px">{getTranslatedString(label)}:</Col>
                <Col flex="0 0 160px">
                  <Form.Item key={name} name={name} rules={rules}>
                    {formItem}
                  </Form.Item>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      ))}
      <Divider orientation="left" plain>
        <FormattedMessage id="application-settings.upload-label" />
      </Divider>
      <Upload
        className="agreement-upload"
        action={`${API_URL}/agreements`}
        maxCount={1}
        fileList={fileList}
        onChange={handleChange}
        headers={{
          Authorization: `Bearer ${localStorage.getItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN)}`,
        }}
        onPreview={onPreview}
        accept="application/pdf"
        beforeUpload={beforeUpload}
        showUploadList={{ showRemoveIcon: false }}
      >
        <Button icon={<UploadOutlined />} type="primary">
          <FormattedMessage id="application-settings.upload-button" />
        </Button>
      </Upload>
      <Form.Item {...tailLayout}>
        <Button loading={loading} type="primary" htmlType="submit">
          <FormattedMessage id="application-settings.submit-button" />
        </Button>
      </Form.Item>
    </Form>
  );
};
