import moment from 'moment';

export const getFormattedDate = (date: string) => date && moment(date).format('MM/DD/YYYY h:mm:ss a');
export const getFormattedEffectiveDate = (date: string) => date && moment(date).format('YYYY-MM-DD');
export const getFormattedTime = (date: string) => date && moment(date).format('HH:mm');
export const getFormattedMinutes = (date: string) => {
  const time = date && date.split(':');
  return Number(time[0]) * 60 + Number(time[1]);
};
export const getTimeFromMinutes = (minutes:number) => {
  let hours = String(Math.floor(minutes / 60));
  let min = String(minutes % 60);
  if (hours.length === 1) {
    hours = `0${hours}`;
  }
  if (min.length === 1) {
    min = `0${min}`;
  }
  return `${hours}:${min}`;
};
