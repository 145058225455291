import { UIViewInjectedProps } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { api } from '@/api';
import { Drawer, Space } from '@/components/antd';
import { Table } from '@/components/table';
import { usersStore } from '@/stores';
import { getTranslatedString } from '@/utils';
import { getFormattedDate } from '@/utils/moment';
import { useMassUpdateCashedStores } from '@/utils/store';

import { columns } from './setup';

export const Form = observer(({ transition }: UIViewInjectedProps) => {
  const { itemId } = transition.router.globals.params;
  const [data, setData]: any = useState({});
  useMassUpdateCashedStores([usersStore]);

  useEffect(() => {
    api.audit.get(itemId).source
      .then(({ data: respData }: any) => {
        setData(respData || {});
      });
  }, [itemId]);

  const onClose = () => {
    transition.router.stateService.go('base-layout.audit');
  };

  const title = getTranslatedString('audit.audit-number', { 0: itemId });

  const { userId, createdAt } = data;
  const user = usersStore.items.find(({ id }) => id === userId);
  const userName = user
    ? (user.name || user.email)
    : 'not found';

  const newData = data.newData || {};
  const originalData = data.originalData || {};
  const keys = Object.keys({ ...newData, ...originalData }).sort();

  const tableData = keys.map((key) => ({
    key,
    newData: newData[key],
    originalData: originalData[key],
  }));

  return (
    <Drawer
      onClose={onClose}
      visible
      title={title}
      footer={false}
      width={window.innerWidth - 250}
    >
      <Space direction="vertical">
        <Space>
          <FormattedMessage id="audit.userName" />
          :
          {userName}
        </Space>
        <Space>
          <FormattedMessage id="audit.createdAt" />
          :
          {getFormattedDate(createdAt)}
        </Space>
        <Table
          rowKey="key"
          columns={columns}
          dataSource={tableData}
          scroll={{ y: '100%' }}
          pagination={false}
        />
      </Space>
    </Drawer>
  );
});
