import { UIView } from '@uirouter/react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PrinterOutlined, SettingOutlined } from '@/components/icons';
import { Button, DatePicker, Input, PageHeader, Select, Space } from '@/components/antd';

import { Table } from '@/components/table';
import { auditStore, usersStore } from '@/stores';
import { onPrint } from '@/utils/common';
import { useMassUpdateCashedStores } from '@/utils/store';

import { getGenericFilters, toQueryFormat } from '@/components/table/utils';
import { columns } from './setup';

const { RangePicker } = DatePicker;

const tables = [
  'activities',
  'addresses',
  'agencies',
  'approver_configs',
  'areas',
  'buildingsAreas',
  'buildingConfigs',
  'attachments',
  'audit',
  'break_times',
  'buildings',
  'colors',
  'employees',
  'employees_sync_errors',
  'languages',
  'migrations',
  'notes',
  'permission_objects',
  'permissions',
  'positions',
  'roles',
  'roles_permissions',
  'settings',
  'shifts',
  'supervisors',
  'typeorm_metadata',
  'users',
  'users_roles',
  'users_settings',
  'working_logs',
  'employees_markup',
  'day_timesheet',
  'week_timesheet',
];

const mapToExtremeTimeValues = arr =>
  arr.map((e, index) => {
    switch (index) {
      case 0:
        return moment(e).set({
          h: 0,
          m: 0,
          s: 0,
          ms: 0,
        });
      case 1:
        return moment(e).set({
          h: 23,
          m: 59,
          s: 59,
          ms: 999,
        });
      default:
        return undefined;
    }
  });
const mapToMoment = arr => arr.map(e => moment(e));
const mapToString = arr => arr.map(e => e.toISOString());

export const Audit = observer(() => {
  const { items } = auditStore;
  const tableRef: any = useRef({});
  const searchRef: any = useRef('');
  const tableParamsRef: any = useRef({ skip: 0, limit: 50 });
  const [tableName, setTableName]: any = useState(null);
  const [searchProperty, setSearchProperty]: any = useState(null);
  const [searchPropertyOptions, setSearchPropertyOptions]: any = useState(null);
  const [search, setSearch]: any = useState(null);
  const [dateRange, setDateRange]: any = useState();

  useMassUpdateCashedStores([usersStore]);

  useEffect(() => {
    try {
      const { newData, originalData } = (items[0] || {}) as any;

      const options = Object.keys({ ...newData, ...originalData })
        .sort()
        .map(table => ({
          label: table,
          value: table,
        }));
      setSearchPropertyOptions(options);
    } catch (err) {}
  }, [items]);
  const updateTable = () => {
    const params = tableParamsRef.current;

    const requestParams = {
      sort: 'id:-1',
      ...params,
      tableName,
      [searchProperty]: search,
      createdAt: dateRange,
    };
    if (!tableName) {
      delete requestParams.tableName;
    }
    if (!searchProperty || !search) {
      delete requestParams[searchProperty];
    }
    if (!dateRange || !dateRange.length) {
      delete requestParams.createdAt;
    }

    if (!dateRange || !dateRange.length) {
      delete requestParams.performedAt;
    }

    auditStore.forceLoad(requestParams);
  };

  useEffect(() => {
    updateTable();
  }, [tableName, searchProperty, search, dateRange]);

  const onTableNameChange = value => {
    setSearch(null);
    setSearchProperty(null);
    setSearchPropertyOptions([]);
    setTableName(value);
  };

  const onSearchPropertyChange = value => {
    setSearchProperty(value);
  };

  const tableNameOptions = tables.map(table => ({
    label: table,
    value: table,
  }));

  const genericFilters = useMemo(() => getGenericFilters(columns), []);

  const onChange = (...args) => {
    tableParamsRef.current = toQueryFormat.apply(null, [genericFilters, ...args]);
    updateTable();
  };

  const onApply = () => {
    setSearch(searchRef.current);
  };

  const onSearchInputChange = e => {
    searchRef.current = e.target.value;
  };

  const onDateChange = e => {
    setDateRange(e && mapToString(mapToExtremeTimeValues(e)));
  };

  return (
    <>
      <PageHeader
        className="r-antd-page-header"
        extra={[
          <Space direction="vertical">
            <Space>
              <RangePicker
                style={{ marginRight: 8 }}
                value={dateRange && mapToMoment(dateRange)}
                onChange={onDateChange}
              />
              <Select
                key="filter"
                showSearch
                allowClear
                placeholder="Select an entity"
                onChange={onTableNameChange}
                filterOption={(input, option: any) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                options={tableNameOptions}
                value={tableName}
                style={{ width: 200 }}
              />
              <Button
                key="setting"
                icon={<SettingOutlined />}
                type="primary"
                shape="circle"
                onClick={tableRef.current.showSettingsModal}
              />
              <Button key="print" icon={<PrinterOutlined />} type="primary" shape="circle" onClick={onPrint} />
            </Space>
            {tableName && (
              <Space>
                <Select
                  key="filter"
                  showSearch
                  allowClear
                  placeholder="Select a property"
                  onChange={onSearchPropertyChange}
                  filterOption={(input, option: any) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  options={searchPropertyOptions}
                  value={searchProperty}
                  style={{ width: 200 }}
                />
                <Input type="text" onChange={onSearchInputChange} />
                <Button key="setting" title="Find" type="primary" shape="round" onClick={onApply}>
                  <FormattedMessage id="audit.button.find" />
                </Button>
              </Space>
            )}
          </Space>,
        ]}
      />
      <Table
        tableRef={tableRef}
        rowKey="id"
        columns={columns}
        dataSource={items.map(item => ({
          ...item,
        }))}
        store={auditStore}
        scroll={{ y: '100%' }}
        onChange={onChange}
      />
      <UIView />
    </>
  );
});
